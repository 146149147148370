import { datadogLogs } from '@datadog/browser-logs';

import { ProfileError } from '../../../errors';
import { finishNode, parseTimestamp, updateNode } from '../common';
import { getCategoryByType } from '../labels';
import {
  NodeTimings,
  ProfileBuilder,
  ProfileNodeCore,
  ProfileNodeWithTimings,
  TransactionEvent,
} from '../types';

export type SamplingProfileNode = {
  core: ProfileNodeCore & {
    totalSampleTimeMS: number;
    selfSampleTimeMS: number;
  };
  parent: SamplingProfileNode | null;
  children: SamplingProfileNode[];
};

const ROOT_NODE_ID = '0';

export class ProfileBuilderV2 implements ProfileBuilder {
  // null until we receive first event
  root: SamplingProfileNode | null = null;
  nodesMap: Map<string, SamplingProfileNode>;
  visibilityLevelMap: Map<string, string>;

  constructor() {
    this.nodesMap = new Map();
    this.visibilityLevelMap = new Map();
  }

  addEvents(events: TransactionEvent[]) {
    events.forEach(rawEvent => {
      const { type, timestamp, event } = rawEvent;
      const eventDate = parseTimestamp(timestamp);

      switch (type) {
        case 'profiler.Sample': {
          // Insert new nodes
          for (const nodeId in event.new_nodes) {
            const newNodeInfo = event.new_nodes[nodeId];

            const newNode: SamplingProfileNode = {
              core: {
                id: nodeId,
                desc:
                  newNodeInfo.desc.type === 'Root'
                    ? { type: 'Transaction' }
                    : newNodeInfo.desc,
                category: getCategoryByType(newNodeInfo.desc.type),
                latestProgress: null,
                startedAt: eventDate,
                finishedAt: null,
                totalSampleTimeMS: 0,
                selfSampleTimeMS: 0,
                path: [],
              },
              parent: null,
              children: [],
            };

            this.nodesMap.set(nodeId, newNode);

            if (nodeId === ROOT_NODE_ID) {
              this.root = newNode;
            }
          }

          // Connect new nodes to their parents
          for (const nodeId in event.new_nodes) {
            const newNode = event.new_nodes[nodeId];
            const parent =
              newNode.parent_id !== null
                ? this.nodesMap.get(newNode.parent_id.toString())
                : null;

            if (!parent) {
              continue;
            }

            const child = this.nodesMap.get(nodeId);

            if (!child) {
              throw new ProfileError(
                `child ${nodeId} not found; should have just been inserted`,
              );
            }

            parent.children.push(child);
            child.parent = parent;
          }

          // Add sample counts
          for (const sample of event.active_leaves) {
            let curNode = this.nodesMap.get(sample.node_id.toString()) || null;

            if (curNode === null) {
              throw new ProfileError(
                `node not found for sample event: ${sample.node_id}`,
              );
            }

            // Update sample counts, from the leaf to the root.
            curNode.core.selfSampleTimeMS += event.period_ms;

            let i = 0;

            while (curNode !== null) {
              curNode.core.totalSampleTimeMS += event.period_ms;
              curNode = curNode.parent;

              i++;

              if (i > 100) {
                throw new ProfileError('looping');
              }
            }
          }

          // Update progress
          for (const nodeID in event.latest_progress) {
            const node = this.nodesMap.get(nodeID);

            if (node) {
              node.core.latestProgress = event.latest_progress[nodeID];
            }
          }

          break;
        }

        case 'profiler.UpdateNodeProgress': {
          updateNode(this.nodesMap, rawEvent);
          break;
        }

        case 'profiler.FinishNode': {
          if (event.node_id === 0 && this.nodesMap.size === 0) {
            // Special case to mitigate https://relationalai.atlassian.net/browse/RAI-20443
            // Do nothing; end up with empty profile.
            datadogLogs.logger.warn('hit special case to mitigate RAI-20443', {
              feature: 'profiler',
            });

            return;
          }

          // Handle final progress update
          const node = this.nodesMap.get(event.node_id.toString());

          if (node) {
            node.core.latestProgress = event.final_progress;
          }

          // Mark finished
          finishNode(this.nodesMap, eventDate, event.node_id.toString());
          break;
        }

        case 'profiler.VisibilityLevelMap': {
          for (const ty in event.map) {
            const vis = event.map[ty];

            if (ty !== undefined && vis !== undefined) {
              this.visibilityLevelMap.set(ty, vis);
            }
          }

          break;
        }

        default: {
          datadogLogs.logger.warn('unhandled event type in profiler', {
            type,
            timestamp,
            event,
            feature: 'profiler',
          });
        }
      }
    });
  }

  extract(lastTime: Date): ProfileNodeWithTimings {
    if (this.root === null) {
      return {
        core: {
          id: '0',
          desc: { type: 'Transaction' },
          category: 'Other',
          latestProgress: null,
          startedAt: new Date(0),
          finishedAt: null,
          path: [],
        },
        children: [],
        timings: {
          done: false,
          selfTime: 0,
          totalTime: 0,
          wallTime: 0,
        },
      };
    }

    return extractRecurse(this.root, lastTime);
  }

  getVisibilityLevelMap(): Map<string, string> {
    return this.visibilityLevelMap;
  }
}

function extractRecurse(
  node: SamplingProfileNode,
  lastTime: Date,
): ProfileNodeWithTimings {
  // Base case: if the node has no children, return the core and timings.
  if (node.children.length === 0) {
    return {
      core: node.core,
      timings: getTimings(node, lastTime),
      children: [],
    };
  }

  // Recursive case: if the node has children, compute their values.
  const children = node.children.map(child => {
    return extractRecurse(child, lastTime);
  });

  return {
    core: node.core,
    timings: getTimings(node, lastTime),
    children,
  };
}

function getTimings(node: SamplingProfileNode, lastTime: Date): NodeTimings {
  return {
    done: node.core.finishedAt !== null,
    selfTime: node.core.selfSampleTimeMS,
    totalTime: node.core.totalSampleTimeMS,
    wallTime: lastTime.getTime() - node.core.startedAt.getTime(),
  };
}
